<script setup lang="ts">
import { USER_TYPE } from "@/constants";

interface Emit {
  (event: "selected", userType: USER_TYPE): void;
}

const emit = defineEmits<Emit>();

const options = computed(() => [
  {
    title: "Employers",
    type: USER_TYPE.employer,
    description: "Looking to hire candidates?",
    icon: "fa6-solid:user-tie",
  },
  {
    title: "Candidates",
    type: USER_TYPE.candidate,
    description: "Looking for work?",
    icon: "fa6-solid:user-graduate",
  },
]);
</script>

<template>
  <div class="flex flex-col sm:flex-row gap-5">
    <div
      v-for="option in options"
      :key="option.title"
      @click="$emit('selected', option.type)"
      class="border-light-gray w-full border-1 rounded-lg group relative bg-white p-3 sm:p-6 hover:border-primary cursor-pointer hover:scale-[1.02] transition-all duration-300"
    >
      <div>
        <div class="flex gap-3 items-center">
          <div>
            <span class="text-primary bg-primary/10 inline-flex rounded-lg p-3 ring-4 ring-white">
              <Icon :name="option.icon" class="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div class="flex flex-col items-start">
            <p class="text-base font-semibold text-dark-gray">
              {{ option.title }}
            </p>
            <p class="mt-1 text-sm text-gray-500">
              {{ option.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
